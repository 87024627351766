import { AppBar, Box, Button, Menu, MenuItem, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, Typography, CircularProgress } from "@mui/material"

import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../logo-G.svg';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { appService } from "../App";
import Link from "./link.component";

export default function HeaderComponent() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    
  }, [appService])

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePortal = async () => {
    setIsLoading(true);
    try {
      const { url } = await appService.getBillingPortal();
      window.location = url;
    } catch(e) {
      setIsLoading(false);
      setAnchorEl(null);
    }
  };

  const handleLogout = () => {
    appService.logout();
    navigate('/');
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton> */}
          <Link href="/">
            <Box
                component="img"
                sx={{
                  height: '48px',
                  marginRight: '10px'
                }}
                alt="Gryd Logo"
                src={logo}
            />
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {appService.loggedIn 
              ? <>
                  Gryd Developer Portal - {appService.company?.name}
                </>
              : <>
                  Gryd Developer Portal
                </>
            }
          </Typography>
          {/* <Button color="inherit">Login</Button> */}

          {appService.loggedIn && (
              <div style={{ marginLeft: 'auto' }}>
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  Welcome {appService.user.forename } -&nbsp;
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handlePortal} disabled={isLoading}>
                    Billing Portal
                    &nbsp;
                    {isLoading && (<CircularProgress size="20px" />)}
                  </MenuItem>
                  <MenuItem
                    href="https://help.gryd.org"
                    target="_blank"
                    component="a">Help Center</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
          )}

          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <List>
              <ListItem button>
                <ListItemText primary="Home" />
              </ListItem>

              <ListItem button>
                <ListItemText primary="About" />
              </ListItem>

              <ListItem button>
                <ListItemText primary="Contact" />
              </ListItem>

              <ListItem button>
                <ListItemText primary="Services" />
              </ListItem>
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
