
export enum DeviceTypes {
    android = 'android',
    iOS = 'iOS',
    browser = 'browser'
}

export interface DeviceInterface {
    deviceIdentifier: string;
    deviceType: DeviceTypes;
    pushToken?: string;
}