import { DeviceInterface } from "./device.interface";

export enum UserType {
    Admin = 'Admin',
    User = 'User',
}

export interface UserRequestInterface {
    mobileNumber?: string;
    password?: string;
    forename?: string;
    surname?: string;
    email?: string;
}

export interface ResetPasswordRequestInterface {
    password: string;
}

export interface CreateUserRequestInterface extends UserRequestInterface {
    device: DeviceInterface;
}

export interface UpdateUserRequestInterface extends UserRequestInterface {

}

export interface AuthenticateUserRequestInterface {
    email: string;
    password: string;
    device: DeviceInterface;
}

export interface EmailCodeRequestInterface {
    email: string;
}

export interface EmailVerifyRequestInterface {
    code: string;
}