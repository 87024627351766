import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './Dashboard.css';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';

import { appService } from '../../App';
import { Box, Card, CardContent, Grid, IconButton, ListItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { config } from '../../config';

function Dashboard() {
  const {
    mixins: { toolbar },
  } = useTheme();

  const [loginData, setLoginData] = useState<any>(null)
  const [usageData, setUsageData] = useState<any>(null);
  const [keyData, setKeyData] = useState<any>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [visibleKeys, setVisibleKeys] = useState<any>({

  });
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
    getUsages();
    getKeys();
  }, []);

  useEffect(() => {
  }, [visibleKeys, toggleKeyVisibility])

  async function getUser() {
    await appService.waitForLogin();
    const data = appService.getCompanyUser();
    setLoginData(data);
  }

  async function getUsages() {
    const data = await appService.getUsages();

    setUsageData(Object.values(data).flat().map((usage: any) => {
      usage.monthDate = new Date(usage.monthDate);
      usage.formattedMonth = usage.monthDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long' })
      return usage;
    }));
  }

  async function getKeys() {
    const data = await appService.getKeys();
    setKeyData(data);
  }

  async function deleteKey(uuid: string) {
    const data = await appService.deleteKey(uuid);
    await getKeys();
  }

  async function addKey() {
    const data = await appService.createKey();
    await getKeys();
  }

  function isKeyVisible(key: string) {
    if(visibleKeys[key]) {
      return true;
    } else {
      return false;
    }
  }

  function toggleKeyVisibility(key: string) {
    if(!isKeyVisible(key)) {
      visibleKeys[key] = key;
    } else {
      delete visibleKeys[key];
    }

    setVisibleKeys({...visibleKeys});
  }
  
  function copyToClipboard(key: string) {
    navigator.clipboard.writeText(key)
    setSnackbarOpen(true);
  }

  return (
    <div className="App" style={{ height: `calc(100vh - (${toolbar?.minHeight}px + ${8}px))` }}>
      <Snackbar
        autoHideDuration={3000}
        open={snackbarOpen}
        ContentProps={{
          'aria-describedby': 'clipboard',
        }}
        message={<span id="clipboard"> API Key Copied to Clipboard </span>}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <Grid
        container
        spacing={2}
        justifyItems="center"
        style={{ padding: '20px' }}
      >
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Card variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                API Keys 
                <Tooltip title="Open API Documentation" placement="top-start">
                  <IconButton edge="end" aria-label="delete" href={config.url.API_URL + 'docs'} target="_blank">
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Key</TableCell>
                      <TableCell align="right">Call Count</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {keyData?.data?.map((key: any) => (
                    <TableRow
                      key={key.uuid}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {isKeyVisible(key.uuid) && (
                          key.key
                        )}
                        {!isKeyVisible(key.uuid) && (
                          new Array(key.key.length + 1).join( '*' )
                        )}
                      </TableCell>
                      <TableCell align="right">{key.callCount}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Show/Hide API Key" placement="top-start">
                          <IconButton edge="end" aria-label="show" onClick={() => toggleKeyVisibility(key.uuid) }>
                            {isKeyVisible(key.uuid) && (
                              <VisibilityOffIcon fontSize='small' />
                            )}
                            {!isKeyVisible(key.uuid) && (
                              <VisibilityIcon fontSize='small' />
                            )}
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Copy API Key to Clipboard" placement="top-start">
                          <IconButton edge="end" aria-label="clipboard" onClick={() => copyToClipboard(key.key) }>
                            <ContentCopyIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete API Key" placement="top-start">
                          <IconButton edge="end" aria-label="delete" onClick={() => deleteKey(key.uuid)}>
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <Button variant="outlined" startIcon={<AddIcon />} onClick={() => addKey() }>
                Create API Key
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Card variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                API Usage
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Month</TableCell>
                      <TableCell align="right">Chargeable Calls</TableCell>
                      <TableCell align="right">Total Calls</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {usageData?.map((usage: any) => (
                    <TableRow
                      key={usage.monthDate}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        { usage.formattedMonth  }
                      </TableCell>
                      <TableCell align="right">{usage.chargeableCount}</TableCell>
                      <TableCell align="right">{usage.totalCount}</TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              {appService?.user && 
                <p>
                  Welcome {appService.user.forename}
                </p>
              }
              </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default Dashboard;
