import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './Forgot.css';

import { appService } from '../../App';
import { Box, Card, CardContent, Typography, CardHeader, Grid, TextField, useTheme } from '@mui/material';
import { useNavigate } from "react-router-dom";

enum ForgotPasswordState {
  START,
  VERIFY,
  RESET,
  NOT_EXISTS,
  SUCCESS
}

function Forgot() {
  const {
    mixins: { toolbar },
  } = useTheme();

  const [codeToken, setCodeToken] = useState<any>(null)
  const [verifyToken, setVerifyToken] = useState<any>(null)
  const [state, setState] = useState<ForgotPasswordState>(ForgotPasswordState.START)
  const navigate = useNavigate();

  useEffect(() => {

  }, []);

  async function handleCode(event: any) {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;

    const response = await appService.requestEmailCode(email);

    if(response) {
      setCodeToken(response.token);
      setState(ForgotPasswordState.VERIFY);
    }
  }

  async function handleVerify(event: any) {
    event.preventDefault();

    if(codeToken) {
      const data = new FormData(event.currentTarget);
      const code = data.get("code") as string;

      const response = await appService.verifyEmailCode(codeToken as string, code);

      if(response) {
        if(!response.exists) {
          setState(ForgotPasswordState.NOT_EXISTS);
        } else {
          setVerifyToken(response.token);
          setState(ForgotPasswordState.RESET);
        }
      }
    }
  }

  async function handleReset(event: any) {
    event.preventDefault();

    if(verifyToken) {
      const data = new FormData(event.currentTarget);
      const code = data.get("password") as string;

      const response = await appService.resetPassword(verifyToken as string, code);

      if(response) {
        navigate('/');
      }

      // Navigate or Success?
      // setState(ForgotPasswordState.SUCCESS);
    }
  }

  return (
    <div className="App" style={{ height: `calc(100vh - (${toolbar?.minHeight}px + ${8}px))` }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100%', paddingTop: '20px', paddingBottom: '20px' }}
      >
        <Card variant="outlined" sx={{ width: '90%', maxWidth: 345 }}>
          <CardContent>
            {state === ForgotPasswordState.START && (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  Enter your email address
                </Typography>
                <Box component="form" onSubmit={handleCode} sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      type="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                  <br />
                  <br />
                  <Button variant="contained" fullWidth type="submit">Verify Email</Button>
                </Box>
              </>
            )}
            {state === ForgotPasswordState.VERIFY && (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  Check your email for a code
                </Typography>
                <Box component="form" onSubmit={handleVerify} sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="code"
                      label="Verification Code"
                      name="code"
                      autoFocus
                    />
                  <br />
                  <br />
                  <Button variant="contained" fullWidth type="submit">Verify Code</Button>
                </Box>
              </>
            )}
            {state === ForgotPasswordState.NOT_EXISTS && (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  User does not exist, have you signed up?
                </Typography>
              </> 
            )}
            {state === ForgotPasswordState.RESET && (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  Enter in your new password
                </Typography>
                <Box component="form" onSubmit={handleReset} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <br />
                  <br />
                  <Button variant="contained" fullWidth type="submit">Reset Password</Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default Forgot;
