import { AuthenticateUserRequestInterface, DeviceTypes, EmailCodeRequestInterface, EmailVerifyRequestInterface, ResetPasswordRequestInterface, SetupCompanyRequestInterface } from "@gryd/library";
import { DeviceUUID } from "device-uuid";
import { config } from "../config";
import Cookies from 'js-cookie';

export class AppService {
    deviceUuid: DeviceUUID = new DeviceUUID();
    user: any;
    company: any;
    loggedIn: boolean = false;
    token?: string | null;

    constructor() {
        this.token = Cookies.get('token');
        this.setupIntercom();
        this.waitForLogin();
    }

    getCompanyUser() {
        return {
            user: this.user,
            company: this.company,
        }
    }

    logout() {
        this.user = null;
        this.company = null;
        this.loggedIn = false;
        this.token = null;
        Cookies.remove('token');

        (window as any).Intercom('shutdown');
        this.setupIntercom();
    }

    async refreshUser() {
        const response = await fetch(config.url.APP_API_URL + 'user', {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        })

        if(!response.ok) {
            throw new Error('Failed')
        }   

        const data = await response.json();
         
        this.user = data.user;
        this.company = data.company;

        this.updateIntercom(data);

        return data;
    }

    async setupIntercom() {
       (window as any).Intercom('boot', {
            app_id: 'so1naseb'
        });
    }
    
    async updateIntercom(data?: any) {
        const intercomData: any = (data.user && data.company) ? {
            email: data.user.email,
            user_id: data.user.uuid,
            user_hash: data.user.hashedUuid,
            name: data.user.forename + ' ' + data.user.surname,
            phone: data.user.mobileNumber,
            created_at: data.user.createdAt,
            company: {
                company_id: data.company.uuid,
                name: data.company.name 
            }
        } : { };

       (window as any).Intercom('update', intercomData);
    }

    async getBillingPortal() {
        const response = await fetch(config.url.APP_API_URL + 'company/billing/portal', {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        })

        if(!response.ok) {
            throw new Error('Failed')
        }

        const data = await response.json();

        return data;
    }

    async getKeys() {
        const response = await fetch(config.url.APP_API_URL + 'company/keys', {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        })

        if(!response.ok) {
            throw new Error('Failed')
        }

        const data = await response.json();

        return data;
    }

    async createKey() {
        const response = await fetch(config.url.APP_API_URL + 'company/keys', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        })

        if(!response.ok) {
            throw new Error('Failed')
        }

        const data = await response.json();

        return data;
    }

    async deleteKey(uuid: string) {
        const response = await fetch(config.url.APP_API_URL + `company/keys/${uuid}`, {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        })

        if(!response.ok) {
            throw new Error('Failed')
        }

        const data = await response.json();

        return data;
    }

    async getUsages() {
        const response = await fetch(config.url.APP_API_URL + 'company/usages', {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        })

        if(!response.ok) {
            throw new Error('Failed')
        }

        const data = await response.json();

        return data;
    }

    async waitForLogin() {
        if(this.loggedIn) {
            return true;
        }

        if(!this.token) {
            return false;
        }

        try {
            await this.refreshUser();
            this.loggedIn = true;
            return true;
        } catch (e) {
            this.loggedIn = false;
            return false;
        }
    }

    async authenticate(email: string, password: string) {
        try {
            const response = await fetch(config.url.APP_API_URL + 'authenticate', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email,
                    password,
                    device: {
                        deviceIdentifier: this.deviceUuid.get(),
                        deviceType: DeviceTypes.browser,
                    }
                } as AuthenticateUserRequestInterface), // body data type must match "Content-Type" header
            });

            if(!response.ok) {
                throw new Error('Failed')
            }

            const data = await response.json();

            this.user = data.user;
            this.company = data.company;
            this.loggedIn = true;
            this.token = data.token;

            Cookies.set('token', data.token);

            this.updateIntercom(data);

            return true;
        } catch(e) {
            return false;
        }
    }

    async requestEmailCode(email: string) {
        try {
            const response = await fetch(config.url.APP_API_URL + 'email/code', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email,
                } as EmailCodeRequestInterface), // body data type must match "Content-Type" header
            });

            if(!response.ok) {
                throw new Error('Failed')
            }

            const data = await response.json();

            return data;
        } catch(e) {
            return false;
        }
    }

    async verifyEmailCode(token: string, code: string) {
        try {
            const response = await fetch(config.url.APP_API_URL + 'email/verify', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    code,
                } as EmailVerifyRequestInterface), // body data type must match "Content-Type" header
            });

            if(!response.ok) {
                throw new Error('Failed')
            }

            const data = await response.json();

            return data;
        } catch(e) {
            return false;
        }
    }

    async resetPassword(token: string, password: string) {
        try {
            const response = await fetch(config.url.APP_API_URL + 'user/reset', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    password,
                } as ResetPasswordRequestInterface), // body data type must match "Content-Type" header
            });

            if(!response.ok) {
                throw new Error('Failed')
            }

            const data = await response.json();

            return data;
        } catch(e) {
            return false;
        }
    }

    async companySignup(
        token: string, 
        companyName: string,
        forename: string,
        surname: string,
        password: string,
        mobileNumber?: string
    ) {
        try {
            const response = await fetch(config.url.APP_API_URL + 'company/setup', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: companyName,
                    user: {
                        forename,
                        surname,
                        password,
                        mobileNumber,
                        device: {
                            deviceIdentifier: this.deviceUuid.get(),
                            deviceType: 'browser'
                        }
                    }
                } as SetupCompanyRequestInterface), // body data type must match "Content-Type" header
            });

            if(!response.ok) {
                throw new Error('Failed')
            }

            const data = await response.json();

            this.user = data.user;
            this.company = data.company;
            this.loggedIn = true;
            this.token = data.token;

            Cookies.set('token', data.token);

            this.updateIntercom(data);

            return data;
        } catch(e) {
            return false;
        }
    }
}