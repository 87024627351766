export enum ConnectedServiceCredentialsType {
    UsernamePasswordCredentials = 'USERNAME_PASSWORD',
    OdometerCredentials = 'ODOMETER',
    TokenCredentials = 'TOKEN'
}

export enum ConnectedServiceMake {
    BMW = 'BMW',
    PEUGEOT = 'PEUGEOT',
    CITROEN = 'CITROEN',
    FIAT = 'FIAT',
    DS = 'DS',
    VAUXHALL = 'VAUXHALL',
    OPEL = 'OPEL'
}

export enum ConnectedService {
    BMW = 'BMW',
    STELLANTIS = 'STELLANTIS'
}

export enum ConnectedServiceRegion {
    Europe = 'EUROPE',
    USA = 'USA',
    China = 'CHINA'
}

export interface ConnectedVehicleAuthenticateInterface {
    username: string;
    password: string;
}