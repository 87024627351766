
import { createTheme, ThemeOptions } from "@mui/material";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: React.CSSProperties['color'];
        };
    }

    interface Palette {
        tertiary: Palette['primary'];
    }

    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        tertiary: true;
    }
}

export const themeOptions: ThemeOptions = {
    palette: {
      mode: 'dark',
      background: {
        default: '#222222',
        paper: '#282c34'
      },
      primary: {
        main: '#119FB7',
      },
      secondary: {
        main: '#AEDAE9',
      },
      tertiary: {
        main: '#2B7585',
      },
    },
  };
  
  export const theme = createTheme(themeOptions);