// Constants.js
const prod = {
    url: {
        APP_API_URL: 'https://app.gryd.org/api/',
        API_URL: 'https://api.gryd.org/'
    }
};
const dev = {
    url: {
        // APP_API_URL: 'http://localhost:8000/app/',
        // API_URL: 'http://localhost:8000/api/'
        //     APP_API_URL: 'http://192.168.1.91:8000/app/',
        //     API_URL: 'http://192.168.1.91:8000/api/'
        APP_API_URL: 'http://craigwhiteside.papertrailio.nordlayerconnect.net:8000/app/',
        API_URL: 'http://craigwhiteside.papertrailio.nordlayerconnect.net:8000/api/'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;