import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './Home.css';

import { appService } from '../../App';
import { Box, Card, CardContent, Grid, TextField, useTheme } from '@mui/material';
import { useNavigate } from "react-router-dom";

function Home() {
  const {
    mixins: { toolbar },
  } = useTheme();

  const [loginData, setLoginData] = useState<any>(null)
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    await appService.waitForLogin();
    const data = appService.getCompanyUser();
    setLoginData(data);
  }

  async function handleAuthentication(event: any) {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;
    const password = data.get("password") as string;

    try {
      const loggedIn = await appService.authenticate(
        email,
        password
      );

      if(loggedIn) {
        setLoginData(appService.getCompanyUser());
        navigate('/dashboard');
      } else {
        // Error
      }
    } catch (e) {
      
    }
  }

  return (
    <div className="App" style={{ height: `calc(100vh - (${toolbar?.minHeight}px + ${8}px))` }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100%', paddingTop: '20px', paddingBottom: '20px' }}
      >
        <Card variant="outlined" sx={{ width: '90%', maxWidth: 345 }}>
          <CardContent>
            {loginData?.user && 
              <p>
                Welcome {loginData.user.forename}
              </p>
            }

            <Box component="form" onSubmit={handleAuthentication} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  type="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              <br />
              <br />
              <Button variant="contained" fullWidth type="submit">Login</Button>
              <br />
              <br />
              <Button color="tertiary" variant="contained" fullWidth href="/forgot">Forgot Password</Button>
            </Box>
            <br />
            <hr />
            <br />
            <Button color="secondary" variant="contained" fullWidth href="/signup">Signup</Button>
            </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default Home;
