import { defer, useLoaderData, Outlet, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import Layout from "./Layout";
import NoPage from "./pages/not-found/NoPage";
import { AppService } from "./services/app.service";
import './App.css';
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import Signup from "./pages/signup/Signup";
import { theme, themeOptions } from "./theme";
import Forgot from "./pages/forgot/Forgot";

export const appService = new AppService();

const ProtectRoute = ({ loggedInCheck, redirect }: { loggedInCheck: boolean, redirect: string }) => {
    const data = useLoaderData();

    if(loggedInCheck == appService.loggedIn) {
      return <Outlet />;
    } else {
      return <Navigate to={redirect} replace={true} />
    }
};

const loginLoader = async () => {
  const result = await appService.waitForLogin();
  return defer({
    loggedIn: result
  })
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} loader={loginLoader}>
      <Route element={<ProtectRoute redirect="/dashboard" loggedInCheck={false} />}>
        <Route 
          index 
          element={
            <Home />
          } 
        />
        <Route 
          path="signup"
          element={
            <Signup />
          }
        />
        <Route 
          path="signup/details"
          element={
            <Signup />
          }
        />
        <Route 
          path="forgot"
          element={
            <Forgot />
          }
        />
      </Route>
      <Route path="dashboard" element={<ProtectRoute redirect="/" loggedInCheck={true} />}>
        <Route 
          index
          loader={loginLoader}
          element={
            <Dashboard />
          }
        />
        <Route 
          path="keys"
          loader={loginLoader}
          element={
            <Signup />
          }
        />
      </Route>
      {/* <Route path="blogs" element={<Blogs />} /> */}
      {/* <Route path="contact" element={<Contact />} /> */}
      <Route path="*" element={<NoPage />} />
    </Route>
  )
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: themeOptions.palette?.background?.default },
        }}
      />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;