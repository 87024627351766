import logo from '../../logo-G.svg';
import './NoPage.css';
import { Grid, useTheme } from '@mui/material';

function NoPage() {
  const {
    mixins: { toolbar },
  } = useTheme();

  return (
    <div className="App" style={{ height: `calc(100vh - (${toolbar?.minHeight}px + ${8}px))` }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: '100%' }}
      >
      <header className="App-header">
        <img src={logo} width="200px" className="App-logo" alt="logo" />
        <p>
          Page Not Found
        </p>
      </header>
      </Grid>
    </div>
  );
}

export default NoPage;
