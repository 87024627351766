import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './Signup.css';

import { appService } from '../../App';
import { Box, Card, CardContent, Typography, CardHeader, Grid, TextField, useTheme, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";

enum SignupState {
  START,
  VERIFY,
  EXISTS,
  SIGNUP,
  SUCCESS
}

function Signup() {
  const {
    mixins: { toolbar },
  } = useTheme();

  const [codeToken, setCodeToken] = useState<any>(null)
  const [verifyToken, setVerifyToken] = useState<any>(null)
  const [state, setState] = useState<SignupState>(SignupState.START)
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {

  }, []);

  async function handleCode(event: any) {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;

    setLoading(true);
    const response = await appService.requestEmailCode(email);
    setLoading(false);

    if(response) {
      setCodeToken(response.token);
      setState(SignupState.VERIFY);
    }
  }

  async function handleVerify(event: any) {
    event.preventDefault();

    if(codeToken) {
      const data = new FormData(event.currentTarget);
      const code = data.get("code") as string;

      setLoading(true);
      const response = await appService.verifyEmailCode(codeToken as string, code);
      setLoading(false);

      if(response) {
        if(!response.exists) {
          setVerifyToken(response.token);
          setState(SignupState.SIGNUP);
        } else {
          setState(SignupState.EXISTS);
        }
      }
    }
  }

  async function handleSignup(event: any) {
    event.preventDefault();

    if(verifyToken) {
      const data = new FormData(event.currentTarget);
      const companyName = data.get("company_name") as string;
      const forename = data.get("forename") as string;
      const surname = data.get("surname") as string;
      const password = data.get("password") as string;

      setLoading(true);
      const response = await appService.companySignup(
        verifyToken as string, 
        companyName,
        forename,
        surname,
        password
      );
      setLoading(false);

      if(response) {
        navigate('/dashboard');
      }
    }
  }

  return (
    <div className="App" style={{ height: `calc(100vh - (${toolbar?.minHeight}px + ${8}px))` }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100%', paddingTop: '20px', paddingBottom: '20px' }}
      >
        <Card variant="outlined" sx={{ width: '90%', maxWidth: 345 }}>
          <CardContent>
            {state === SignupState.START && (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  Enter your email address
                </Typography>
                {loading && (
                  <CircularProgress />
                )}
                <Box component="form" onSubmit={handleCode} sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      type="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                  <br />
                  <br />
                  <Button variant="contained" disabled={loading} fullWidth type="submit">Verify Email</Button>
                </Box>
              </>
            )}
            {state === SignupState.VERIFY && (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  Check your email for a code
                </Typography>
                {loading && (
                  <CircularProgress />
                )}
                <Box component="form" onSubmit={handleVerify} sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="code"
                      label="Verification Code"
                      name="code"
                      autoFocus
                    />
                  <br />
                  <br />
                  <Button variant="contained" disabled={loading} fullWidth type="submit">Verify Code</Button>
                </Box>
              </>
            )}
            {state === SignupState.EXISTS && (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  User already exists, do you want to log in?
                </Typography>
                <Button variant="contained" fullWidth href="/">Login</Button>
              </> 
            )}
            {state === SignupState.SIGNUP && (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  Signup Details
                </Typography>
                {loading && (
                  <CircularProgress />
                )}
                <Box component="form" onSubmit={handleSignup} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="company_name"
                    label="Company Name"
                    name="company_name"
                    type="text"
                    autoFocus
                  />
                  <hr />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="forename"
                    label="First Name"
                    name="forename"
                    type="text"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="surname"
                    label="Last Name"
                    name="surname"
                    type="text"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <br />
                  <br />
                  <Button variant="contained" disabled={loading} fullWidth type="submit">Signup</Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default Signup;
