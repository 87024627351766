import { styled, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import HeaderComponent from './components/header.component';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function Layout() {
    return (
        <>
            <main style={{height:"100vh"}}>
                {/* <ToolBar />
                <Sides />
                <Backdrop /> */}
                <HeaderComponent />
                <Offset />
                <Outlet />
            </main>
        </>
    );
}

export default Layout;